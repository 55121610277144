import { motion } from "framer-motion";
import React from "react";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2009- 2024</p>
          <h2 className="text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Software Engineer"
            subTitle="JP Morgan Chase & Co. - (2024 - Present)"
            result="USA"
            des="JP Morgan Chase & Co. is an American multinational investment bank and financial services holding company headquartered in New York City."
          />
          <ResumeCard
            title="Java Developer"
            subTitle="Synechron - (2023 - 2024)"
            result="USA"
            des="Synechron is a leading digital transformation consulting firm focused on the financial services industry and is working to Accelerate Digital initiatives for banks, asset managers, and insurance companies around the world."
            />
          <ResumeCard
            title="Full-stack Developer"
            subTitle="SafeAir Company - (2020 - 2022)"
            result="UAE"
            des="SafeAir Company is a private aviation company that provides a wide range of services, including aircraft management, charter, and maintenance."
          />
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">&nbsp;&nbsp;&nbsp;</p>
          <h2 className="text-4xl font-bold">&nbsp;&nbsp;&nbsp;&nbsp;</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Associate Developer"
            subTitle="SafeAir Company - (2019 - 2020)"
            result="Kenya"
            des="SafeAir Company is a private aviation company that provides a wide range of services, including aircraft management, charter, and maintenance."
          />
          <ResumeCard
            title="Junior Developer"
            subTitle="SafeAir Company - (2018 - 2019)"
            result="Kenya"
            des="SafeAir Company is a private aviation company that provides a wide range of services, including aircraft management, charter, and maintenance."
          />
          <ResumeCard
            title="Network and IT Support"
            subTitle="Telecomunication and  Trading Companies (2009 - 2017)"
            result="Ethiopia, UAE"
            des= "Telecomunication and Trading Companies that provide a wide range of services, including network and IT support, and trading."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
