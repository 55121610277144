import React from 'react';
import { projectOne, projectThree, projectTwo } from "../../assets/index";
import Title from '../layouts/Title';
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="LIBRARY MANAGEMENT SYSTEM"
          des=" Library system allowing users to manage collections and patron records. Created class and sequence diagrams to model use cases; designed user interface to support system functionality."
          src={projectOne}
        />
        <ProjectsCard
          title="HOTEL BOOKING SYSTEM"
          des=" Web application, conducting back-end and front-end development from scratch."
          src={projectTwo}
        />
        <ProjectsCard
          title="BADGE AND MEMBERSHIP SYSTEM"
          des=" Badge checking system allowing members with different roles to use their membership plan by scanning badge manage collections and patron records."
          src={projectThree}
        />
      </div>
    </section>
  );
}

export default Projects