import { FaCloud, FaCode, FaCogs, FaGlobe, FaLaptopCode } from "react-icons/fa";
import { SiAntdesign } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <FaLaptopCode />,
    title: "Software Engineering",
    des: "Build robust, scalable software solutions with our experienced engineering team. Whether it’s system architecture, back-end development, or cloud infrastructure, we cover all aspects of software engineering. We help businesses create secure, high-performance applications tailored to their specific needs.",
  },
  {
    id: 2,
    icon: <FaCode />,
    title: "App Development",
    des: "Deliver web applications tailored to your business needs. With a focus on user-centric design and performance, we build apps that not only meet functional requirements but also provide a seamless and engaging user experience. From front-end to back-end, we ensure the highest standards of development.",
  },
  {
    id: 3,
    icon: <FaCloud />,
    title: "Cloud Integration",
    des: "Enhance the flexibility and scalability of your applications by integrating cloud solutions into your architecture. Our expertise in Java-based cloud technologies ensures smooth transitions to cloud platforms, improved system performance, and cost-efficiency. Whether you're adopting a hybrid cloud or going fully cloud-native, we’ll guide your business to the right solution.",
  },
  {
    id: 4,
    icon: <FaCogs />,
    title: "DevOps Engineering",
    des: "Streamline your software development lifecycle with efficient DevOps practices. By integrating development and operations teams, we ensure faster delivery, improved collaboration, and continuous deployment of high-quality applications. Our expertise in cloud platforms, CI/CD pipelines, and infrastructure automation helps businesses achieve scalable, reliable solutions.",
  },
  {
    id: 5,
    icon: <SiAntdesign />,
    title: "UX Design",
    des: "Create intuitive and visually appealing interfaces that offer seamless user experiences. Our UX design team collaborates closely with developers to ensure that both functionality and aesthetics align perfectly. With a Java back-end foundation, we ensure that the design is not only engaging but also integrates flawlessly with the system's architecture.",
  },
  {
    id: 6,
    icon: <FaGlobe />,
    title: "Hosting Websites",
    des: "Ensure the best performance and security for your websites with our comprehensive hosting solutions. We leverage Java-based servers and advanced technologies to deliver fast, reliable, and scalable hosting that fits your business needs. Whether deploying enterprise applications or high-traffic websites, we ensure your online presence is always available and secure.",
  },
];
